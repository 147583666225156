
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated. It will be overwritten.

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_473ZG1s0N",
  "aws_user_pools_web_client_id": "rpuouhctd63hli3jibghso54b",
  "oauth": {},
  "aws_cognito_username_attributes": [],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
    "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
    "SMS"
  ],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL"
  ],
  "aws_appsync_graphqlEndpoint": "https://763zgauezzbe5nbmjlkbpb7kvq.appsync-api.us-east-1.amazonaws.com/graphql",
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_download_endpoint": "https://oz4cwt0ow6.execute-api.us-east-1.amazonaws.com",
  "stage": "devArthur"
};
export default awsmobile;
