import React, { useState } from 'react'
import { Grid, Box, Button, Paper, Accordion, AccordionDetails, AccordionSummary, List, ListItem } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp, Grading, PersonOutlined, SettingsOutlined, Logout, LockOpen } from '@mui/icons-material'
import logo from '../../Images/mesh-logo-white.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

const navItems = [
  // Removing incomplete nav items
  // {
  //   name: "Dashboard",
  //   path: "/dashboard",
  // },
  // {
  //   name: "Credentials",
  //   path: "/credentials",
  // },
  // {
  //   name: "Professionals",
  //   path: "/professionals",
  // },
  // {
  //   name: "Batch Orders",
  //   path: "/batchOrders",
  //   icon: <Grading />,
  // },
  // {
  //   name: "Profiles",
  //   path: "/profiles",
  //   icon: <PersonOutlined />,
  // },
  {
    name: "Orders",
    path: "/orders",
    icon: <Grading />,
  },
  {
    name: "Settings",
    path: '/settings',
    icon: <SettingsOutlined />,
    subPaths: [
      // {
      //   name: 'Profile',
      //   path: '/settings/profile',
      // },
      {
        name: 'Users',
        path: '/settings/users',
        icon: <PersonOutlined />,
      },
      {
        name: 'API Keys',
        path: '/settings/apiKeys',
        icon: <LockOpen />,
      },
      // {
      //   name: 'Billing',
      //   path: '/settings/billing',
      // },
    ],
  },
]

const Drawer = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { signOut } = useAuthenticator((context) => [context.user])

  const goToPage = (path) => {
    navigate(path)
  }

  return (
    <Box height={'100%'}>
      <Paper elevation={2} square sx={{
        boxSizing: 'border-box',
        display: 'flex',
        width: '15.5rem',
        flexDirection: 'column',
        alignItems: 'space-between',
        height: '100%',
        backgroundColor: 'emphasis.dark',
      }}>
        <Box container height="100%" sx={{
          padding: '1.5rem 0rem 1.5rem 0rem',
        }}>
          <Box sx={{ padding: '0rem 1.5rem 0rem 1.5rem' }}>
            <LogoBox />
          </Box>
          <List sx={{ padding: '0rem 1.5rem 0rem 1.5rem' }}>
          {navItems.map((val, index) => (
            <NavItem
              key={index}
              text={val.name}
              icon={val.icon}
              onPressed={({ path }) => goToPage(path || val.path)}
              currentLocation={location.pathname}
              isActive={location.pathname.includes(val.path)}
              disabled={val.name === 'Batch Orders' || 'Settings' ? false : true}
              item={val}
            />
          ))}
          </List>
          
        </Box>
        <LogOut signOut={signOut} />
      </Paper>
    </Box>
  )
}

const LogoBox = () => {
  return (
    <Box width='100%'>
      <img src={logo} style={{
        width: '7.5rem',
        margin: '0',
        paddingBottom: '3rem',
      }}/>
    </Box>
  )
}

const NavItem = ({ text, onPressed, currentLocation, icon, isActive, disabled, item }) => {
  if(item.subPaths){
    return (
      <SubMenuItem
        text={text}
        icon={icon}
        onPressed={onPressed}
        currentLocation={currentLocation}
        isActive={isActive}
        disabled={disabled}
        item={item} />
    )
  }

  return (
    <ListItem disablePadding>
      <Button
        startIcon={icon}
        fullWidth
        sx={{
        paddingTop: '1rem',
        paddingBottom: '1rem',
        justifyContent: 'flex-start',
        color: 'emphasis.contrastText',
        backgroundColor: isActive ? 'primary.dark' : 'transparent',
        textTransform: 'none',
        fontSize: '1rem',
        ":hover": {
          backgroundColor: 'primary.main',
          color: 'emphasis.contrastText',
        },
        // Ensure that the disabled button isn't greyed out
        "&.Mui-disabled": {
          background: 'primary.main',
          color: 'emphasis.contrastText',
        },
      }} disabled={disabled} onClick={onPressed}>
        {text}
      </Button>
    </ListItem>
  )
}

const SubMenuItem = ({ text, onPressed, currentLocation, icon, item }) => {
  return (
    <ListItem disablePadding>
      <Box sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
      }}>
        <NavItem
          text={text}
          onPressed={() => {}} // no-op
          isActive={false}
          icon={icon}
          disabled={true}
          item={{}} />
        <List sx={{ paddingLeft: '1rem' }}>
          {item.subPaths.map((val, index) => (
            <NavItem
              key={index}
              text={val.name}
              icon={val.icon}
              onPressed={() => {onPressed({ path: val.path })}}
              currentLocation={currentLocation}
              isActive={currentLocation.includes(val.path)}
              disabled={false}
              item={val}
            />
          ))}
        </List>
      </Box>
    </ListItem>
  )
}

const LogOut = ({ isActive, signOut }) => {
  return (
    <Box padding='1.5rem'>
      <Button
        startIcon={<Logout />}
        fullWidth
        sx={{
        paddingTop: '1rem',
        paddingBottom: '1rem',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
        justifyContent: 'flex-start',
        color: 'emphasis.contrastText',
        backgroundColor: isActive ? 'primary.dark' : 'transparent',
        textTransform: 'none',
        fontSize: '1rem',
        ":hover": {
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
        },
      }} onClick={() => signOut()}>
        Log Out
      </Button>
    </Box>
  )
}

export default Drawer
