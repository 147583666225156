import React, { useState, useContext, useEffect } from 'react';
import { Box, List, Chip, Stack, Typography, Divider, CircularProgress, Grid, Button } from '@mui/material';
import { WorkflowVerificationContext } from '../../services/workflowVerificationService';
import LabelStack from '../../Components/LabelStack';


// TODO: Replace these with configuration data from the backend
const VSKU_NAMES = {
  'v-pro-license@1.0': 'Professional License',
  'v-pro-license-sandbox@1.0': 'Professional License',
  'v-bus-w-tin@1.0': 'Business Verification',
  'v-bus-w-tin-sandbox@1.0': 'Business Verification',
  'v-proof-of-business@1.0': 'Proof of Business',
  'v-proof-of-business-sandbox@1.0': 'Proof of Business',
  'v-w9-document@1.0': 'W-9 Document',
  'v-w9-document-sandbox@1.0': 'W-9 Document',
}
const CLAIM_NAMES = {
  'licenseeName': 'Licensee Name',
  'licenseIdentifier': 'License Number',
  'licenseStatus': 'License Status',
  'licenseStatusAsIs': 'License Status As Is',
  'licenseType': 'License Type',
  'licenseSubtype': 'License Subtype',
  'licenseTypeAsIs': 'License Type As Is',
  'licenseIssuedDate': 'License Issue Date',
  'licenseExpirationDate': 'License Expiration Date',
  'jurisdiction': 'Issuing Authority',
  'businessWebsiteUrl': 'Business Website',
  'taxIdentificationNumber': 'Taxpayer Identification Number (TIN)',
  'businessAddress': 'Business Address',
  'businessName': 'Business Name',
  'fileName': 'File Name',

}

const CLAIM_TYPE = {
  licenseExpirationDate: 'date',
  licenseIssuedDate: 'date',
}

const formatValue = ({ name, value }) => {
  if(CLAIM_TYPE[name] === 'date') {
    if (!value) {
      return '-'
    }
    const date = new Date(parseInt(value, 10))
    return date.toLocaleDateString()
  }
  return value
}

const getFields = ({ claims, results }) => {
  if (results && results.length > 0) {
    return results
  }
  if (claims && claims.length > 0) {
    return claims
  }
  return []
}

const VerificationOrderItem = ({ item, index }) => {
  const { vsku, labels } = item
  const fields = getFields(item)
  return (
    <Box key={`order-item-${index}`}>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h5'>{VSKU_NAMES[vsku]}</Typography> 
        <LabelStack labels={labels} />
      </Stack>
      
      <Grid container sx={{ backgroundColor: 'rgba(245, 245, 245, 0.60)', padding: '1rem', textAlign: 'left' }}>
        {fields.map((result, idx) => 
        <React.Fragment key={`order-item-${idx}`}>
          <Grid item xs={6}>
            <Typography textAlign='left' variant='subtitle1'>{CLAIM_NAMES[result.name] || result.name}</Typography>
          </Grid>
          <Grid item xs={6}>
          <Typography textAlign='left'>{formatValue(result)}</Typography>
          </Grid>
        </React.Fragment>,
        )}
      </Grid>
    </Box>
    
  )
}

export const VerificationSidePanel = ({ orderId }) => {
  const { getWorkflowVerification } = useContext(WorkflowVerificationContext)
  const [orderDetails, setOrderDetails] = useState()
  useEffect(async () => {
    if (orderId) {
      const details = await getWorkflowVerification({ orderId })
      console.log('got details', details)
      setOrderDetails(details)
    }
  }, [getWorkflowVerification, orderId])
  if (!orderDetails) {
    return (
      <Box sx={{ padding: '2rem', height: '100%', minWidth: '40vw' }}>
        <CircularProgress />
      </Box>
    )
  }

  const { title, businessId, labels, jurisdictions, items } = orderDetails || {}
  const orderAttributes = [
    { label: 'Order ID:', value: orderId },
    { label: 'Business ID:', value: businessId },
  ].filter(({ value }) => value)
  return (
    <Box sx={{ padding: '2rem', height: '100%', width: '40vw' }}>
        <Stack direction='column' justifyContent='space-between' height='100%'>
        <Box>
          <Stack direction='row' sx={{ alignItems: 'end' }} spacing='0.25rem'>
            <Typography variant='h5' color='emphasis.main'>{title}</Typography>
            <Typography variant='h6' color='emphasis.light' lineHeight='1.5'>{['', ...jurisdictions].join(' / ')}</Typography>
          </Stack>
          <Box sx={{ backgroundColor: 'rgba(245, 245, 245, 0.60)' }} marginY='1rem' padding='1rem' >
            {orderAttributes.map(({ label, value }, index) => 
              <Stack key={`attribute-${index}`} direction='row' justifyContent='space-between' spacing='0.25rem'>
                <Typography variant='subtitle2'>{label}</Typography>
                <Typography variant='subtitle2'>{value}</Typography>
              </Stack>,
            )}
          </Box>
          <LabelStack labels={labels} />
          <Divider sx={{ marginTop: '1.5rem', marginBottom: '1.5rem' }} />
          <List>
            {items.map((item, index) => <VerificationOrderItem item={item} index={index} />)}
          </List>
          
        </Box>
        {/* Disable this button until we have this functionality
        <Box>
          <Button variant='outlined'>Cancel Order</Button>
        </Box> */}
      </Stack>
        
      
    </Box>
    
  )
}