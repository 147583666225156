/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const whoami = /* GraphQL */ `
  query Whoami {
    whoami {
      userId
      customerId
      customer {
        customerId
        status
        customerName
        customerUrl
        customerLogo
        entitlements
      }
      emailAddress
      status
      role
      allowEditing
      fullName
      firstName
      lastName
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($userId: ID!) {
    getUser(userId: $userId) {
      userId
      customerId
      customer {
        customerId
        status
        customerName
        customerUrl
        customerLogo
        entitlements
        vows {
          vowId
          vowConfigId
          description
          items {
            vskuId
            vsku {
              vskuId
              type
              inputClaims
              outputResults
            }
            vspecId
            isRequired
            isRepeatable
          }
          redirectSettings {
            redirectUri
            includeUser
          }
        }
      }
      emailAddress
      status
      role
      allowEditing
      fullName
      firstName
      lastName
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers {
    listUsers {
      userId
      customerId
      customer {
        customerId
        status
        customerName
        customerUrl
        customerLogo
        entitlements
        vows {
          vowId
          vowConfigId
          description
          items {
            vskuId
            vsku {
              vskuId
              type
              inputClaims
              outputResults
            }
            vspecId
            isRequired
            isRepeatable
          }
          redirectSettings {
            redirectUri
            includeUser
          }
        }
      }
      emailAddress
      status
      role
      allowEditing
      fullName
      firstName
      lastName
    }
  }
`;
export const viewApiKeys = /* GraphQL */ `
  query ViewApiKeys($input: ViewKeysInput) {
    viewApiKeys(input: $input) {
      apiKeyId
      name
      status
      message
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($customerId: ID!) {
    getCustomer(customerId: $customerId) {
      customerId
      status
      customerName
      customerUrl
      customerLogo
      entitlements
      vows {
        vowId
        vowConfigId
        description
        items {
          vskuId
          vsku {
            vskuId
            type
            inputClaims
            outputResults
          }
          vspecId
          isRequired
          isRepeatable
        }
        redirectSettings {
          redirectUri
          includeUser
        }
      }
    }
  }
`;
export const validateInvite = /* GraphQL */ `
  query ValidateInvite($inviteId: ID) {
    validateInvite(inviteId: $inviteId) {
      inviteId
      inviteStatus
      inviteExpiration
      emailAddress
      userId
      customer {
        customerId
        status
        customerName
        customerUrl
        customerLogo
        entitlements
        vows {
          vowId
          vowConfigId
          description
          items {
            vskuId
            vsku {
              vskuId
              type
              inputClaims
              outputResults
            }
            vspecId
            isRequired
            isRepeatable
          }
          redirectSettings {
            redirectUri
            includeUser
          }
        }
      }
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $search: ListNotificationInput
    $pagination: ContinuationInput
  ) {
    listNotifications(search: $search, pagination: $pagination) {
      nextContinuation
      notifications {
        userId
        timezone
        notifications {
          notificationChannel
          notificationTypes
          phoneNumber
          emailAddress
          hookUrl
          channel
          secret
        }
      }
    }
  }
`;
export const getWorkflowVerification = /* GraphQL */ `
  query GetWorkflowVerification($orderId: ID!) {
    getWorkflowVerification(orderId: $orderId) {
      orderId
      vowId
      businessId
      userId
      items {
        vspecId
        vsku
        jurisdiction
        claims {
          name
          value
        }
        results {
          name
          value
        }
        labels
        itemStatus
        lastVerifiedTime
      }
      orderStatus
      labels
      title
      jurisdictions
      orderCreationTime
      lastUpdatedTime
      orderCompletionTime
    }
  }
`;
export const queryWorkflowVerifications = /* GraphQL */ `
  query QueryWorkflowVerifications($search: QueryWorkflowVerificationInput!) {
    queryWorkflowVerifications(search: $search) {
      orders {
        orderId
        vowId
        businessId
        userId
        orderStatus
        labels
        title
        jurisdictions
        orderCreationTime
        lastUpdatedTime
        orderCompletionTime
      }
      pagination {
        total
        nextOffset
      }
    }
  }
`;
export const listVowConfigs = /* GraphQL */ `
  query ListVowConfigs {
    listVowConfigs {
      vowId
      vowConfigId
      description
      items {
        vskuId
        vsku {
          vskuId
          type
          inputClaims
          outputResults
        }
        vspecId
        isRequired
        isRepeatable
      }
      redirectSettings {
        redirectUri
        includeUser
      }
    }
  }
`;
export const getVowConfig = /* GraphQL */ `
  query GetVowConfig($vowId: String!) {
    getVowConfig(vowId: $vowId) {
      vowId
      vowConfigId
      description
      items {
        vskuId
        vsku {
          vskuId
          type
          inputClaims
          outputResults
        }
        vspecId
        isRequired
        isRepeatable
      }
      redirectSettings {
        redirectUri
        includeUser
      }
    }
  }
`;
export const getVowAlias = /* GraphQL */ `
  query GetVowAlias($alias: String!) {
    getVowAlias(alias: $alias) {
      alias
      vowId
      customerId
    }
  }
`;
export const getBusiness = /* GraphQL */ `
  query GetBusiness($businessId: ID!) {
    getBusiness(businessId: $businessId) {
      businessId
      aliases {
        namespace
        identifier
      }
    }
  }
`;
export const getBusinessByAlias = /* GraphQL */ `
  query GetBusinessByAlias($namespace: String!, $identifier: String!) {
    getBusinessByAlias(namespace: $namespace, identifier: $identifier) {
      businessId
      aliases {
        namespace
        identifier
      }
    }
  }
`;
